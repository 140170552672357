function menuIcon() {
 let header = document.querySelector('.header');
 if (!header) {
  return
 }
 window.addEventListener('scroll', (e) => {
  if (window.scrollY >= 120) {
   document.body.classList.add('fixed');
  } else {
   document.body.classList.remove('fixed');
  }
 })
 let menu = header.querySelector('.menu-main-menu-container');
 let menuButton = document.querySelector('.menu_icon');
 menuButton.addEventListener('click', (e) => {
  e.preventDefault();

  menu.classList.toggle('menu-opened');
  menuButton.classList.toggle('menu-opened');
  document.body.classList.toggle('no-scroll');
 })
}
menuIcon()