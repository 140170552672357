function aboutUsMenu() {
 let sideBar = document.querySelector('.sidebar');
 if (!sideBar) {
  return
 }

 let links = sideBar.querySelectorAll('a');
 if (links.length < 0) {
  return
 }

 links[0].classList.add('active');
 let href;
 let sections = document.querySelectorAll('.section-anchors');
 let sectionPosition;
 let current;
 for (let i = 0; i < links.length; i++) {
  links[i].addEventListener('click', (e) => {
   e.preventDefault();

   links.forEach(element => {
    element.classList.remove('actived');
   })

   links[i].classList.add('actived');
   href = links[i].getAttribute('href');
   let topOffset = document.querySelector('.menu-bar-flex').offsetHeight + 20;
   let targetPostiton = document.querySelector(`.section-anchors${href}`).getBoundingClientRect().top;
   let scrollPosition = targetPostiton - topOffset;
   window.scrollBy({
    top: scrollPosition,
    behavior: 'smooth'
   })
  })
 }

 window.addEventListener('scroll', function () {
  sections.forEach((section) => {
   if (section.getBoundingClientRect().top <= 650) {
    current = section.getAttribute('id');
   }
  })
  links.forEach(link => {
   if (link.href.includes(current)) {
    sideBar.querySelector('.active').classList.remove('active');
    link.classList.add('active')
   }
  })
 })
}
aboutUsMenu()