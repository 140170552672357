function scrollAnimation() {
 let scrollSection = document.querySelector('.scrolling-tape');
 if (!scrollSection) {
  return
 }

 let scrollContainer = scrollSection.querySelector('.header-scrolling');
 scrollContainer.setAttribute('data-animate', true)
 let scrollsElements = scrollContainer.querySelector('.container_word');
 let clone = scrollsElements.querySelectorAll('.single-word');
 let wordClone;
 clone.forEach(element => {
  wordClone = element.cloneNode(true);
  wordClone.setAttribute('aria-hidden', true);
  scrollsElements.append(wordClone);
 });
}scrollAnimation()