function homeSlider() {
 let slider = document.querySelector('.posts-slider');
 if (!slider) {
  return
 }

 let sliderContainer = slider.querySelector('.posts-container');
 let sliderItems = sliderContainer.querySelectorAll('.single-post');
 let sliderItemsWidth = sliderContainer.querySelector('.single-post').getBoundingClientRect().width + 30;
 let next = slider.querySelector('.next');
 let prev = slider.querySelector('.prev');
 let media1 = window.matchMedia('(min-width: 951px) and (max-width: 1400px)');
 let media2 = window.matchMedia('(max-width: 950px)');
 let dotsWrapper = slider.querySelector('.dots');
 let slide = 0;
 let dot;
 let swipeEffect = new Hammer(sliderContainer);

 function countSlide() {
  if (!media1.matches && !media2.matches) {
   return 3
  } else if (media1.matches) {
   return 2
  } else if (media2.matches) {
   return 1
  }
 }

 function countDots() {
  if (!media1.matches && !media2.matches) {
   return 2
  } else if (media1.matches) {
   return 1
  } else if (media2.matches) {
   return 0
  }
 }

 function generateDots() {
  for (let i = 0; i < (sliderItems.length - countDots()); i++) {
   dot = document.createElement('span');
   dot.setAttribute('data-slide-id', i)
   dot.classList.add('dot');
   dotsWrapper.append(dot);
   setTimeout(() => {
    dotsWrapper.querySelectorAll('.dot')[0].classList.add('active');
   }, 100);
  }
  dotsWrapper.querySelectorAll('.dot').forEach(dot => {
   dot.addEventListener('click', () => {
    dotsWrapper.querySelectorAll('.dot').forEach(inActiveElement => {
     inActiveElement.classList.remove('active');
    })
    dot.classList.add('active');
    slide = dot.getAttribute('data-slide-id');
    sliderContainer.style.transform = `translateX(${-sliderItemsWidth * slide}px)`;
   })
  })
 }
 generateDots()

 function moveDots() {
  slider.querySelectorAll('.dot.active').forEach(element => {
   element.classList.remove('active');
  })

  slider.querySelector(`.dot[data-slide-id="${slide}"]`).classList.add('active');
 }

 function moveSlide() {
  sliderContainer.style.transform = `translateX(${-sliderItemsWidth * slide}px)`;
 }

 next.addEventListener('click', (e) => {
  e.preventDefault();
  slide++;

  if (slide > sliderItems.length - countSlide()) {
   slide = 0;
  }

  moveDots()
  moveSlide()
 })

 prev.addEventListener('click', (e) => {
  e.preventDefault();
  slide--;

  if (slide < 0) {
   slide = sliderItems.length - countSlide();
  }
  moveDots()
  moveSlide()
 })

 swipeEffect.on('swipe', ev => {
  if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
   next.click();
  }

  if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
   prev.click();
  }
 });
}
homeSlider()